<template>
  <div>
    <!-- section dashboard -->
    <section class="section section-secondary jpadding">
      <div class="jcard jcard-main jcard-nohover">
        <!-- TITLE -->
        <div>
          <h3><i class="fad fa-list"></i> Users List</h3>
        </div>
        <!-- Search -->
        <!-- TODO removed filters for test version: :filters=filters -->
        <SearchAndFilters class="d-flex mb-3" :searchList=usersList ></SearchAndFilters>
        <!-- FILTERS -->
        <div class="d-flex mb-3">
          <div>
            <i class="fal fa-filter"></i>
            <button class="btn btn-outline-primary ml-2" @click="allUsers()">
              All
            </button>
            <button class="btn btn-outline-primary ml-2" @click="filterUsers(0)">
              Press
            </button>
            <button class="btn btn-outline-primary ml-2" @click="filterUsers(1)">
              Members
            </button>
            <button class="btn btn-outline-primary ml-2" @click="filterUsers(2)">
              Owners
            </button>
            <button class="btn btn-outline-primary ml-2" @click="filterUsers(3)">
              Admin
            </button>
          </div>
          <router-link class="btn btn-primary ml-auto" to="create-user">
            <i class="fa fa-plus"></i>
          </router-link>
        </div>
        <!-- TABLE -->
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <th scope="col">Type</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Joined On</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in usersList" :key="user.id">
              <td>{{ user.id.substring(0, 8) }}</td>
              <td>{{ userTypeList[user.type] }}</td>
              <td>{{ user.firstName + " " + user.lastName }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.createdString }}</td>
              <td>
                <span class="badge badge-success text-uppercase">{{
                    user.statusName
                }}</span>
              </td>
              <td>
                <button class="jbtn jbtn-icon jbtn-blue" @click="openAccount(index)">
                  <i class="fa fa-search"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import SearchAndFilters from "@/components/general/SearchAndFilters"
import C from "@/const";
import moment from "moment";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const auth = firebase.auth;

export default {
  name: "UsersList",
  components: {
    SearchAndFilters,
  },
  data() {
    return {
      userStatusList: C.STATUS.STATUS_INDEX,
      userTypeList: C.TYPE_INDEX,
      homeTitle: "Bonjour ",
      usersList: [],
      filters: []
    };
  },
  methods: {
    addUserDocToList: function (userDoc) {
      const user = userDoc.data();
      user.id = userDoc.id;
      // making sure that anything can be searched by adding the correspoinding strings to the object
      user.name = user.firstName + " " + user.lastName;
      user.createdString = moment(C.getDateFromTimestamp(user.createdOn)).format("DD-MM-YYYY");
      user.typeName = C.TYPE_INDEX[user.type];
      user.statusName = C.STATUS.STATUS_INDEX[user.status];
      this.usersList.push(user);
    },
    allUsers: function () {
      const id = auth.currentUser.uid;
      console.log(id);
      this.usersList.splice(0);
      db.collection(C.COLLECTION.USERS)
        .orderBy("createdOn", "desc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.addUserDocToList(doc);
          });
          this.filters.push({
            title: "Created between",
            type: C.FILTER_TYPE.DATE,
            field: "createdOn",
            selection: null
          });
          this.filters.push({
            title: "User Type",
            type: C.FILTER_TYPE.MULTI_SELECTION,
            field: "typeName",
            selection: this.userTypeList,
          });
          this.filters.push({
            title: "Status",
            type: C.FILTER_TYPE.MULTI_SELECTION,
            field: "statusName",
            selection: C.STATUS.STATUS_INDEX,
          });
        });
    },
    filterUsers: function (type) {
      const id = auth.currentUser.uid;
      console.log(id);
      this.usersList.splice(0);
      db.collection(C.COLLECTION.USERS)
        .where("type", "==", type)
        .orderBy("createdOn", "desc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.addUserDocToList(doc);
          });
        });
    },
    openAccount(index) {
      this.$router.push({
        name: "UserAccount",
        params: { id: this.usersList[index].id },
      });
    },
    openCreateUser() {
      this.$store.commit("open", "createUser");
      this.$router.push({
        name: "CreateUser",
        params: {},
      });
    },
    getFormatDate(date) {
      return new Date(date.seconds * 1000);
    },

  },
  mounted() {
    this.allUsers();
  },
};
</script>

<style lang="scss" scoped></style>
